/*
SIMPLE QUOTE GENERATOR FORM
*/
var form = document.getElementById("quoteform");

function getValues() {
    var selectedtitles = [];
    var selectedparts = [];
    var selectedtotal = 0;
    var formele = form.elements;
    for (var i = 0, element; element = formele[i++];) {
        if (element.type === "checkbox") {
            if (element.checked) {
                var price = parseInt(element.value);
                var title = element.getAttribute('data-title');
                var Obj = new Object;
                Obj["title"] = title;
                Obj["price"] = price;
                selectedtitles.push(title);
                selectedparts.push(Obj);
                selectedtotal = selectedtotal + price;
                console.log(title + " | " + price);
            }
        }
    }
    return [selectedtitles, selectedparts, selectedtotal];
}

function updateHelper(vanTotal) {
    var vanTotal = vanTotal;
    //update parent class
    //$("#totalbox").addClass("active");

    //display the Price
    var divobj = document.getElementById('totalPrice');
    divobj.style.display = 'block';
    divobj.innerHTML = "<em>Parts Price</em> <span>$" + vanTotal + "</span>";

    //display the Total
    var divobj = document.getElementById('totalTotal');
    divobj.style.display = 'block';
    divobj.innerHTML = "<em>Total Price</em> <span>$" + vanTotal + "</span>";

    //display the Total Top
    var divobj2 = document.getElementById('totaltop');
    divobj2.style.display = 'block';
    divobj2.innerHTML = "<em>Total Price</em> <span>$" + vanTotal + "</span>";

    var formtotal1 = document.getElementById('formTotal1').value = vanTotal;
    //var formtotal2 = document.getElementById('formTotal2').value = electTotal2;
    //var formtotal3 = document.getElementById('formTotal3').value = totalLabor2;
    //var formtotal4 = document.getElementById('formTotal4').value = total2;
    var formtotal4 = document.getElementById('formTotal4').value = vanTotal;

}

function updateReview(vanPartsList) {
    var vanPartsList = vanPartsList;

    //display the Parts List
    var divobj = document.getElementById('reviewParts');
    divobj.style.display = 'block';
    divobj.innerHTML = "";

    for (var i = 0, element; element = vanPartsList[i++];) {
        divobj.innerHTML += "<li><span>" + element.title + "</span><i> | </i><em>$" + element.price + "</em></li>";
    }

}

function updatequote() {
    var values = getValues();
    var selectedtitles = values[0];
    var selectedparts = values[1];
    var totalPrice = values[2];

    //Van Total Price
    var vanTotal = (totalPrice);
    //Add in Labor and Engineering Percent
    var totalLabor = (vanTotal * .05);
    var totalLabor2 = Math.round(totalLabor * 100) / 100;
    //Add in Labor and Engineering Percent
    var total = (vanTotal + (vanTotal * .05));
    var total2 = Math.round(total * 100) / 100;
    //console.log(vanTotal);

    //Update Frontend
    updateHelper(vanTotal);
    updateReview(selectedparts);
}

function checkAll(bx) {
    var cbs = document.getElementsByTagName('input');
    for (var i = 0; i < cbs.length; i++) {
        if (cbs[i].type == 'checkbox') {
            cbs[i].checked = bx.checked;
        }
    }
}